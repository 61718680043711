import React, {useContext, useEffect, useState} from "react";
import { MainContext } from '../../contexts/Main.context';
import { TableWrapper } from "../../components/Helpers/TableWrapper";
import { CustomPagination } from "components/Common/CustomPagination";
import { Card, CardBody, Container, Col, Row, Label, Button } from "reactstrap"
import "../Payments/uv-Styles.scss";

export const UvFiles = () => {

  const columns = [
    {name: 'Nombre del archivo'},
    {name: 'Fecha'},
    {name: 'Descargar'},
  ];

  const defaultFilter = {
    date_from:'',
    date_to: '',
    name:'',
    id:'',
    category: 'documents',
    subcategory: 'water_collection_lurin'
  };

  const {backend,showLoader, formatter} = useContext(MainContext);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [rows, setRows] = useState([]);
  const [selectedTab, setSelectedTab ] = useState('1');
  const [selectedSubTab, setSelectedSubTab] = useState(null);
  const [filter, setFilter] = useState(defaultFilter);

  useEffect(() => {
      pullData();
    }, [page]
  );

  useEffect(()=>{
    pullData()
  }, [filter])

  const pullData = async () => {
    const {data, meta} = await backend.getFiles({page, where: filter});
    if(data){
      setTotalPages(meta.lastPage);
      setRows(data);
    }
  };

  const downloadDocument = async (filename, url) => {
    if(filename){
      showLoader(true);
      const resp = await backend.getProtectedImageUrl(filename);
      window.open(resp.data);
      showLoader(false);
    }else{
      //nothing to do here
    }
  }

  const filterCategories = [
    { id: '1', label: 'Estatuto, Reglamento y Escala de sanciones', value: 'documents' },
    { id: '8', value: 'newsletters', label: 'Boletines'},
    { id: '2', label: 'Asambleas', value: 'assemblies' },
    { id: '3', label: 'Balances', value: 'balance_j' },
    { id: '4', label: 'Documentos de agua', value: 'water_documents',
      subcategories: [
        { id: '1', label: 'Captación de agua de rio Lurin', value: 'water_collection_lurin', },
        { id: '2', label: 'Estudio de pozo', value: 'well_study' },
        { id: '3', label: 'Proyecto de regadío preliminar', value: 'pre_irrigation_project' },
        { id: '4', label: 'Estudio de agua', value: 'water_study' },
        { id: '5', label: 'Documentos varios', value: 'various_documents' }
      ]
    },
    // { id: '5', label: 'Estudio de agua', value: 'water_study' },
    { id: '6', label: 'Saneamiento', value: 'sanitation' },
    { id: '7', label: 'Inscripcion de Consejo Directivo', value: 'new_association' },
    { id: '9', label: 'Informes', value: 'reports' },
    { id:'10', label: 'Reunión CD La Raya con CD Cooperativa de Essalud', value:'essalud_meeting' },
    { id:'11', label: 'Arrendamiento a terceros de área de uso exclusivo', value:'exclusive_area_rental' }
  ]

  const active = {
    background: '#57bb6e',
    borderColor: '#57bb6e',
    color: '#fff',
    marginTop: '10px'
  }

  const deactive = {
    backgroundColor: '#ececec',
    borderColor: '#b5b3b3',
    color: '#7a7777',
    marginTop: '10px'
  }

  return(
    <React.Fragment>
      <div className="page-content" id="uv_payments">
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={12} md={6} className="d-flex justify-content-start mb-2"> <h4> Documentos </h4> </Col>
            <Col xs={12} md={6}>
              <Row className="d-flex justify-content-end">
                <Col xs={12} md={4} className=""></Col>
                <Col xs={12} md={4} className=""></Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-4 d-flex align-items-center">
            <Col xs={12} >
              <Label className="mt-3"> Categoría: </Label>
            </Col>
            {filterCategories ? filterCategories.map(((cat, index) =>
                <Col key={index} xs={'auto'} md={'auto'} className="mt-0 mb-0">
                  <Button
                    id={cat.id}
                    value={cat.value}
                    style={selectedTab === cat.id ?  active : deactive}
                    onClick={(ev)=> {
                      const _form = {...filter};
                      _form.category = ev.target.value;
                      if(ev.target.value === 'water_documents') {
                        _form.subcategory = 'water_collection_lurin';
                        setSelectedSubTab('1');
                      } else _form.subcategory = ''
                      setFilter(_form);
                      setSelectedTab(ev.target.id);
                    }}
                  >
                    {cat.label}
                  </Button>
                </Col>
            )):(<></>)}
            {selectedTab && (() => {
              const selectedCategory = filterCategories.find(cat => cat.id === selectedTab);
              return selectedCategory && selectedCategory.subcategories ? (
                <Col xs={12} className="mt-3">
                  <Label className="mt-3"> Subcategoría: </Label>
                  <Row>
                    {selectedCategory.subcategories.map((subcat, subIndex) => (
                      <Col key={subIndex} xs={'auto'} md={'auto'} className="mt-0 mb-0">
                        <Button
                          id={subcat.id}
                          value={subcat.value}
                          style={selectedSubTab === subcat.id ? active : deactive}
                          onClick={(ev) => {
                            const _form = { ...filter };
                            _form.subcategory = ev.target.value;
                            setFilter(_form);
                            setSelectedSubTab(ev.target.id);
                          }}
                        >
                          {subcat.label}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </Col>
              ) : null;
            })()}
          </Row>
          <Col lg="12 mb-4">
            <Card className="no-hover">
              <CardBody>
                <div className="table-responsive text-nowrap">
                  <TableWrapper headers={columns}>
                    {rows ? rows.map((doc =>
                      <tr key={doc.id}>
                        <td className="text-nowrap ">{doc.name}</td>
                        <td className="text-nowrap ">{formatter.date(doc.createdAt)}</td>
                        <td className="text-nowrap ">
                          <div className="row-buttons-container flex-nowrap">
                            <button type="button" onClick={()=>{downloadDocument(doc.filename)}} className="btn bg-warning" data-toggle="PayModal" data-target="#myModal">
                              Descargar
                            </button>
                          </div>
                        </td>
                      </tr>)) : (<></>)}
                  </TableWrapper>
                </div>

                <Row className="mt-4">
                  <CustomPagination currentPage={page} setPage={setPage} totalPages={totalPages}/>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};
