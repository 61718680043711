import React, {useContext, useEffect, useState} from 'react';
import Select from "react-select";
import PropTypes from 'prop-types';
import {MainContext} from '../../contexts/Main.context'
import {Input, Label, Modal, Row, Button, Col, CardTitle} from 'reactstrap';

export const Create = ({active, toggle}) => {

  const defaultForm = {
    createdAt: '',
    filename: '',
    documentUrl: '',
    landId: '',
    name: '',
    category: '',
    subcategory: ''
  }

  const {backend, showLoader, toastAlert, changeHandler} = useContext(MainContext);
  const [form, setForm] = useState(defaultForm);
  const [lot, setLots] = useState([]);
  const [showSubcategory, setShowSubcategory] = useState(false)

  useEffect(() => {
    author();
    pullData();
  }, [])

  useEffect(() => {
    if(form.category === 'water_documents' ){
      setShowSubcategory(true);
    } else {
      setShowSubcategory(false)
    }
  }, [form])

  const author = () => {
    const userData = backend.getSession();
    const  authorId =   userData.data.user ? userData.data.user.id : '';
    const _form = {...form}
    _form.authorId = authorId
    setForm(_form)
  }
  const pullData = async () => {
    const {data} = await backend.getLots({limit: 999, attributes:["id","lot","slug"]});
    if(data){
      setLots(data);
    }
  };
  const options = (withAll = true, array) => {
    const _options = withAll ? [] : [];
    _options.push({id: 0, value: 0,  label: 'Todos'})
    array.map((el) => _options.push({id:el.id, value: el.id,  label:  el.slug ? el.slug : el.id}));
    return _options;
  };


  const submitHandler = () => {
    showLoader(true);
    uploadData()
  };

  const uploadData = async () => {
    const resp = await backend.uploadDocument(form);
    showLoader(false);
    if (resp.status) {
      toastAlert({body: 'Subido correctamente', color: 'primary'});
      toggle('create');
      location.reload();
    } else {
      toastAlert({body: 'Error inesperado', color: 'warning'});
    }
  }

  useEffect(()=>{
    console.log(form)
  },[form])

  return (
    <Modal
      size="l"
      centered
      isOpen={active}
      toggle={() => { toggle('create'); }}>
      <Row>
        <CardTitle className="mt-4 mx-3">
          Ingresa los datos del nuevo archivo
        </CardTitle>
        <Button
          onClick={() => {toggle('create');}}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close">
        </Button>
      </Row>
      <Row className="modal-body">
        <Col xs={12} className="mb-3">
          <Label>Título:</Label>
          <Input
            name="name"
            required={true}
            className="form-control"
            placeholder="Escribe un Título"
            onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
        </Col>
        <Col xs={12}  sm={6} className="mb-3">
          <Label>Subir archivo (max 30mb):</Label>
          <Input
            type="file"
            name="filename"
            required={true}
            className="form-control"
            placeholder="Select date"
            onChange={(ev)=>{changeHandler(ev,form,setForm, true)}}/>
        </Col>
        <Col xs={12} sm={6} className="mb-3">
          <Label>URL de archivo:</Label>
          <Input
            name="documentUrl"
            required={true}
            className="form-control"
            placeholder="Url "
            onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
        </Col>
        <Col xs={12}  sm={6} className="mb-3">
          <Label>Lote:</Label>
          <Select
            name="landId"
            isMulti={true}
            required={true}
            placeholder="Ingresar..."
            options={options(true, lot)}
            onChange={(selected)=>{
              const result = selected.filter(el => el.value == 'All');
              const _form = {...form};
              result[0] ? _form.landId = "" : _form.landId = selected.map(el => el.value).join();
              setForm(_form);}}
          />
        </Col>
        <Col xs={12} sm={6} className="mb-3">
          <Label>Fecha de creación:</Label>
          <Input
            type='date'
            name="createdAt"
            required={true}
            className="form-control"
            onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
        </Col>
        <Col xs={12}className="mb-3">
          <Label>Categoría:</Label>
          <Select
            name="category"
            isMulti={false}
            required={true}
            placeholder="Seleccionar..."
            options={[
              { id: '1', value: 'documents', label: 'Estatuto, Reglamento y Escala de sanciones' },
              { id: '8', value: 'newsletters', label: 'Boletines'},
              { id: '9', value: 'reports', label: 'Informes'},
              { id: '2', value: 'assemblies', label: 'Asambleas' },
              { id: '3', value: 'balance_j', label: 'Balances' },
              { id: '4', value: 'water_documents', label: 'Documentos de agua' },
              { id: '6', value: 'sanitation', label: 'Saneamiento' },
              { id: '7', value: 'new_association', label: 'Inscripcion de Consejo Directivo' },
              { id:'10', value:'essalud_meeting', label: 'Reunión CD La Raya con CD Cooperativa de Essalud' },
              { id:'11', value:'exclusive_area_rental', label: 'Arrendamiento a terceros de área de uso exclusivo' }
            ]}
            onChange={(selected)=>{
              const _form = {...form};
              _form.category = selected.value;
              setForm(_form);
            }}
          />
        </Col>
        {showSubcategory ?
          <Col xs={12}  className="mb-3">
            <Label>Subcategoría:</Label>
            <Select
              name="subcategory"
              isMulti={false}
              required={true}
              placeholder="Seleccionar..."
              options={[
                { id: '1', value: 'water_collection_lurin', label: 'Captación de agua de rio Lurin' },
                { id: '2', value: 'well_study', label: 'Estudio de pozo' },
                { id: '3', value: 'pre_irrigation_project', label: 'Proyecto de regadío preliminar' },
                { id: '4', value: 'water_study', label: 'Estudio de agua' },
                { id: '5', value: 'various_documents', label: 'Documentos varios'}
              ]}
              onChange={(selected)=>{
                const _form = {...form};
                _form.subcategory = selected.value;
                setForm(_form);
              }}
            />
          </Col>
          : <></>}
        <Col xs={12}>
          <Button
            className="btn btn-success w-100 p-2"
            data-dismiss="modal"
            aria-label="Close"
            onClick={()=> submitHandler()}>
            Guardar cambios
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
Create.propTypes = {
  active: PropTypes.bool,
  toggle: PropTypes.func
};